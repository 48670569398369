.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.header-opt {
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 20px;
}
.hero-background {
  background: linear-gradient(
      to right,
      rgba(245, 245, 245, 0.8),
      rgba(245, 245, 245, 0.8)
    ),
    url("../src/assets/common-bg.svg");
}
.heading-primary {
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  color: #111;
  font-weight: 800;
}
.css-p1 {
  padding: 160px;
}
.nav-link{
  cursor:  pointer !important;
}
.contact-css7{
  cursor: pointer;
  cursor: pointer;
}
.contact-css7:hover{
  text-decoration: underline;
}
.text-primary {
  color: #333 !important;
  font-size: 1.3rem !important;
  text-align: center !important;
  width: 100% !important;
  line-height: 1.6 !important;
  margin-top: 27px;
}
.heading-sec__main {
  display: block;
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
  color: #111;
}
.section-container-white {
  background-color: #fafafa;
  padding: 50px;
}
.section-container-transparent {
  background-color: transparent;
  padding: 50px;
}
.contact-fleld-container {
  text-align: start;
  width: 62%;
  padding: 53px;
  border-radius: 12px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 40%);
  background: white;
}
.heading-sec__sub {
  display: block;
  text-align: center;
  color: #555;
  font-size: 18px;
  font-weight: 400;
  max-width: 80rem;
  margin: auto;
  line-height: 1.6;
}
.about__content-title {
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 3rem;
  text-align: center;
}
.css-skill {
  padding: 0.7rem 1.3rem;
  font-size: 0.9rem;
  background: rgba(153, 153, 153, 0.2);
  border-radius: 5px;
  font-weight: 600;
  color: #666;
}
.contact__form-label {
  color: #666;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
  text-align: left;
}

.contact__form-input {
  color: #333;
  padding: 19px;
  width: 100%;
  border: 1px solid #ebebeb;
  font-size: 15px;
  letter-spacing: 0;
  background: #f0f0f0;
  border-radius: 5px;
  font-weight: 600;
}
@media only screen and (max-width: 1200px) {
  .css-p1 {
    padding: 50px;
  }
  .heading-primary {
    font-size: 3rem;
  }
  .contact-fleld-container{
    padding: 35px 25px;
    width: 100%;
  }
  .section-container-white{
    padding: 50px 25px;
  }
  .section-container-transparent{
    padding: 50px 25px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
